import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

class DefaultTemplate extends Component {
    render() {
        const data = this.props.data.wpPage
        const acfBlocks = data.template.acfFlexibleContent.blocks
        const title = data.seo.title || data.title
        return (
            <Layout title={title} description={data.seo.metaDesc} path={data.uri}>
                <FlexibleContent blocks={acfBlocks} />
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query($id: String!) {
	wpPage(id: { eq: $id }) {
        title
        uri
        seo {
            title
            metaDesc
        }
        template {
            ... on WpDefaultTemplate {
              ...defaultTemplateQuery
            }
        }
    }
}
`

export default DefaultTemplate